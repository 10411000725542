<template>
  <div class="mx-8">
      <trac-back-button>Back</trac-back-button>

    <trac-loading v-if="isLoading" />
    <trac-modal
      v-if="reuploadProductModalCtrl"
      @close="reuploadProductModalCtrl = false"
    >
      <trac-validation-observer slim v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(reuploadFailedProduct)"
          class="max-w-xl ma w-full mt-12"
        >
          <div class="mt-8 text-center">
            <h3 class="mb-2">Kindly edit information below and re-upload</h3>
            <p class="text-gray-600 text-xs">
              This upload failed because some information were not rightly
              captured in their supposed order
            </p>
          </div>
          <div class="">
            <trac-validation-provider
              name="product title"
              rules="required"
              v-slot="{ errors }"
            >
              <trac-input
                class="mt-6"
                v-model="selectedProduct.title"
                placeholder="Item"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <trac-validation-provider
              name="product price"
              rules="required|positive|greaterthanzero"
              v-slot="{ errors }"
            >
              <trac-input
                class="mt-6"
                v-model.trim.number="selectedProduct.price"
                placeholder="Price"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <div class="mt-4" v-if="selectedProduct.manage_stock">
              <trac-validation-provider
                name="stock"
                rules="required|positive|greaterthanzero"
                v-slot="{ errors }"
              >
                <trac-input
                  class="mt-6"
                  v-model="selectedProduct.stock"
                  placeholder="Stock"
                  :disabled="!selectedProduct.manage_stock"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
            <div class="mt-4">
              <trac-validation-provider
                name="category"
                rules="required"
                v-slot="{ errors }"
              >
                <trac-dropdown-exteneded
                  :neededProperty="'name'"
                  selector="Select Category"
                  placement="left"
                  :options="categories"
                  @optionSelected="selectCategory($event)"
                  v-model="selectedCategory"
                  class=""
                >
                </trac-dropdown-exteneded>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>

            <div class="mt-4">
              <trac-validation-provider
                name="store"
                rules="required"
                v-slot="{ errors }"
              >
                <trac-dropdown-exteneded
                  :neededProperty="'name'"
                  placement="left"
                  :options="stores"
                  @optionSelected="setStore($event)"
                  class=""
                  v-model="selectedStore"
                >
                </trac-dropdown-exteneded>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>

            <div class="flex items-center my-4">
              <input
                v-model="selectedProduct.manage_stock"
                type="checkbox"
                name=""
                id="manage-stock"
              />
              <label for="manage-stock" class="text-xs pl-3 cursor-pointer"
                >Manage Stock</label
              >
            </div>
          </div>
          <trac-button buttonType="submit" class="uppercase text-xs w-full"
            >Re-upload</trac-button
          >
        </form>
      </trac-validation-observer>
    </trac-modal>

    <div class="mt-8" v-if="uploadRecords">
      <p class="text-xs mb-2">Products upload</p>
      <h3 class="font-bold">Total Failed Upload: {{ uploadRecords.length }}</h3>
    </div>

    <div class="flex justify-end">
      <trac-button variant="outline" disabled>EXPORT PRODUCTS</trac-button>
    </div>

    <div class="mt-8 mb-8 overflow-x-scroll text-xs" v-if="uploadRecords">
      <table class="w-full table-auto">
        <thead class="bg-blue-100">
          <tr class="">
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              NO.
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              TITLE
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              PRICE
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              CATEGORY
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              MANAGE STOCK
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              STOCK
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              STORE NAME
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="uploadRecords.length > 0">
          <tr
            :class="index % 2 === 0 ? '' : 'bg-gray-100'"
            v-for="(product, index) in uploadRecords"
            :key="index"
          >
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ index + 1 }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ product.title | truncate(40, "...") }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ product.price | formatPrice }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ product.category }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ product.manage_stock ? "True" : "False" }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ product.stock }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ product.store_name }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              <button
                class="text-primaryBlue"
                @click="openReuploadModal(product, index)"
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <div class="flex justify-center items-center h-48 w-full">
              <p class="text-gray-600 text-xs">All products uploaded successfully.</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      reuploadProductModalCtrl: false,
      isLoading: false,
      uploadRecords: null,
      selectedProduct: null,
      selectedCategory: null,
      selectedStore: null,
      stores: null,
      categories: null,
      selectedProductIndex: null,
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchAllFailedToUploadProducts();
    await this.fetchAllStores();
    await this.fetchAllCategories();
    this.isLoading = false;
  },
  computed: {
    batchNumber() {
      return this.$route.params.batchNumber;
    },
    validReUploadData() {
      return true;
    },
  },
  methods: {
    setStore(store) {
      this.selectedStore = store;
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    openReuploadModal(product, index) {
      this.selectedProductIndex = index;

      this.selectedProduct = { ...product };
      this.reuploadProductModalCtrl = true;
    },
    async reuploadFailedProduct() {
      this.isLoading = true;

      const payload = {
        id: this.selectedProduct.id,
        BatchId: this.selectedProduct.BatchId,
        BusinessID: GET_USER_BUSINESS_ID(),
        store_name: this.selectedStore.name,
        category: this.selectedCategory.name,
        title: this.selectedProduct.title,
        cost_price: this.selectedProduct.cost_price,
        price: this.selectedProduct.price,
        description: "",
        manage_stock: this.selectedProduct.manage_stock,
        stock: this.selectedProduct.stock,
        variation_name: "",
        is_valid: false,
        CreatedAt: this.selectedProduct.CreatedAt,
      };

      const res = await this.$store.dispatch(
        "RE_UPLOAD_FAILED_PRODUCT",
        payload
      );

      if (res.status) {
        this.selectedProduct = null;
        this.reuploadProductModalCtrl = false;
        // Remove record
        this.uploadRecords.splice(this.selectedProductIndex, 1);
      } else {
      }
      eventBus.$emit("trac-alert", {
        message: res.message,
      });

      this.isLoading = false;

      // if (res.status) {
      //   // this.uploadRecords = res.data;
      // } else {
      // }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.stores = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES"];

      if (res.status) {
        const data = res.data || [{ name: "", count: 0 }];
        if (data.length > 0) {
          this.categories = data.slice(1, data.length);
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async fetchAllFailedToUploadProducts() {
      const res = await this.$store.dispatch(
        "FETCH_FAILED_BULK_UPLOAD_PRODUCTS",
        {
          batchNumber: this.batchNumber,
        }
      );

      if (res.status) {
        this.uploadRecords = res.data || [];
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Failed to fetch records.",
        });
      }
    },
  },
};
</script>

<style>
</style>